import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/Hero/Hero"
import FlexibleContentSimple from "../../components/FlexibleContentSimple/FlexibleContentSimple"

const Project = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    heading,
    text,
    image,
    flexibleContentSimple,
  } = data.project

  return (
    <Layout>
      <Seo title={metaTitle || heading} description={metaDescription} />
      <Hero heading={heading} text={text} image={image[0]} />
      <section className="py-28 md:py-56">
        <div className="container">
          <div className="grid grid-cols-12 space-y-12">
            {flexibleContentSimple &&
              flexibleContentSimple.map(item => (
                <FlexibleContentSimple key={item.id} {...item} />
              ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Project

export const pageQuery = graphql`
  query ProjectQuery($uri: String) {
    project: craftProjectsDefaultEntry(uri: { eq: $uri }) {
      metaTitle
      metaDescription
      heading: title
      text: summary
      image {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
      flexibleContentSimple {
        ...flexibleContentSimpleFragment
      }
    }
  }
`
